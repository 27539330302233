import {SlotData} from '@/interfaces/SlotInsertComplete';

import inView from './inView';

const data = {};

export default (id: SlotData['slotName']) => {
	if (!data[id]) {
		data[id] = {
			viewIds: [],
			timerIds: [],
			eventListeners: [],
		};
	}

	const {viewIds, timerIds, eventListeners} = data[id];

	return {
		addEventHandler(node: HTMLElement | Window, type: string, handler) {
			if (node) {
				eventListeners.push({data: {type, handler}, node});

				node.addEventListener(type, handler);
			}
		},

		addTimer(id) {
			timerIds.push(id);
		},

		addInViewWatcher(id) {
			viewIds.push(id);
		},

		clearEventHandlers() {
			while (eventListeners.length) {
				const {
					node,
					data: {type, handler},
				} = eventListeners.shift();

				if (node) {
					node.removeEventListener(type, handler);
				}
			}
		},

		clearTimers() {
			while (timerIds.length) {
				clearTimeout(timerIds.shift());
			}
		},

		clearInViewWatchers() {
			while (viewIds.length) {
				inView.unwatch(viewIds.shift());
			}
		},

		clearAll() {
			this.clearEventHandlers();
			this.clearTimers();
			this.clearInViewWatchers();
		},
	};
};
