import {BannerData, DwhData, Slot, SlotData, XrayData} from '@/interfaces/SlotInsertComplete';
import getSlotHandlerKeeper from '@/utils/handlerKeeper';
import inView from '@/utils/inView';
import {logAdmanAnalytics, sendXray, sendXrayWithChain, updateChain} from '@/utils/xray';

function sendChain(slotData: SlotData, dwh: DwhData, source = 'adman') {
	const chain = [];
	const {slotBlockNode, slotName} = slotData;

	const xray: XrayData = {
		source,
		chain,
		dwh,
	};

	const handlerKeeper = getSlotHandlerKeeper(slotName);

	updateChain(chain, ['draw'], slotData);

	if (slotBlockNode) {
		if (slotBlockNode.offsetParent) {
			updateChain(chain, ['visible'], slotData);

			if (inView.is(slotBlockNode)) {
				updateChain(chain, ['visible_in_view', 'visible_in_view_start'], slotData);
				logAdmanAnalytics(source, dwh);
			} else {
				handlerKeeper.addInViewWatcher(
					inView.watch(slotBlockNode, () => {
						sendXrayWithChain(xray, ['visible_in_view', 'visible_in_view_after'], slotData);
						logAdmanAnalytics(source, dwh);
					}),
				);

				updateChain(chain, ['visible_no_in_view'], slotData);
			}
		} else {
			updateChain(chain, ['hidden', 'hidden_visible'], slotData);
		}
	} else {
		updateChain(chain, ['hidden', 'hidden_no_in_dom'], slotData);
	}

	sendXray(xray);
}

export const logSlotBlockState = (slotData: SlotData, banner: BannerData, dwh: DwhData) => {
	const {
		slotId,
		bannerId,
		source: bannerSource,
		format: bannerFormat,
		count: bannerCount,
		is_expensive: isExpensive,
	} = banner;

	dwh = {
		...dwh,
		bannerId,
		bannerSource,
		bannerFormat,
		bannerCount,
		isExpensive,
	};

	// FMAIL-22838
	if (slotId && slotId !== dwh.slotId) {
		dwh.slotId = slotId;
	}

	sendChain(slotData, dwh);

	return dwh;
};

export const logJsonSlotBlockState = (slotData: SlotData, adsData: Slot, dwh: DwhData) => {
	const bannerSource = adsData.mark === 'Директ' ? 'direct' : 'target';
	const bannerFormat = adsData.format;

	dwh = {
		...dwh,
		bannerSource,
		bannerFormat,
	};

	sendChain(slotData, dwh, 'mimic');

	return dwh;
};
