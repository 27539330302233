import {DwhData, SlotData} from '@/interfaces/SlotInsertComplete';
import {logSlotClick} from '@/logger/click';

export const onWindowBlurHandler = (getState) => {
	const {slotClickData} = getState();

	if (slotClickData) {
		logSlotClick(slotClickData);
	}
};

export const onFrameMouseLeaveHandler = (setState) => {
	setState({
		slotClickData: null,
	});
};

export const onFrameMouseEnterHandler = (evt: MouseEvent, params) => {
	const {slotData, dwh, getState, setState} = params;

	const target = evt.target as HTMLElement;
	const {frameMouseLeaveTimer} = getState();

	clearTimeout(frameMouseLeaveTimer);

	setState({
		slotClickData: {target, dwh, slotData},
		frameMouseLeaveTimer: setTimeout(() => onFrameMouseLeaveHandler(setState), 1000),
	});
};
