import {getBlockers, getPrefixes} from '@/utils/adblockerDetector';
import {RADAR_BLOCKER_MAP, RADAR_NAME_PREFIXES_MAP} from '@/utils/constants';
import {sendXray, sendXrayWithChain} from '@/utils/xray';

export const sendInitLog = () => {
	const chain = [];

	const prefixes = getPrefixes();

	for (let prefix of prefixes) {
		prefix = RADAR_NAME_PREFIXES_MAP[prefix] || prefix;

		chain.push(prefix);
	}

	const blockers = getBlockers();

	if (blockers.length) {
		chain.push('blocker');

		for (let blocker of blockers) {
			blocker = RADAR_BLOCKER_MAP[blocker] || blocker;

			for (let prefix of prefixes) {
				prefix = RADAR_NAME_PREFIXES_MAP[prefix] || prefix;

				chain.push(`pr-${prefix}_bl-${blocker}`);
			}
		}
	}

	if (chain.length) {
		sendXray({chain});
	}

	sendXrayWithChain({}, ['init']);
};
