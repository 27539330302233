import {DwhData, Slot, SlotData} from '@/interfaces/SlotInsertComplete';
import {checkJbJsonComment} from '@/logger/checkRbJson';
import {logMIMICSendClick, onSlotClickHandler} from '@/logger/click';
import {getSlotBlockData} from '@/logger/data';
import {onFrameMouseEnterHandler, onFrameMouseLeaveHandler} from '@/logger/mouseActions';
import {logJsonSlotBlockState, logSlotBlockState} from '@/logger/visibility';
import getFeatures, {FEATURES, getFeatureData} from '@/utils/features';
import getSlotHandlerKeeper from '@/utils/handlerKeeper';
import {getSlotAllData} from '@/utils/helpers';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const logSlotState = (slotData: SlotData, params) => {
	const {dwh, dwhChain, getState, setState} = params;

	const features = getFeatures();

	const directReadyTimeout = getFeatureData('adman-insert-context-timeout', 1800);
	const logSidebarLower = features.has(FEATURES.ADMAN_LOG_SIDEBAR_LOWER);

	const {slotNode, slotName, slotAllHTMLData} = slotData;

	const handlerKeeper = getSlotHandlerKeeper(slotName);

	handlerKeeper.clearAll();

	const onReady = () => {
		const slotAllHTMLDataAfterRender = logSidebarLower
			? getSlotAllData(slotNode.innerHTML)
			: slotAllHTMLData;

		for (const slotHTMLData of slotAllHTMLDataAfterRender) {
			for (const banner of slotHTMLData) {
				const {fallback = []} = banner;
				const {slotBlockNode, slotBlockName, creativeType} = getSlotBlockData(banner);

				let actualDwh = null;

				if (slotBlockNode && (!fallback.length || slotBlockNode.offsetHeight)) {
					actualDwh = logSlotBlockState({...slotData, slotBlockNode, slotBlockName}, banner, {
						...dwh,
						creativeType,
					});
				} else {
					const logFallback = fallback.some((fallbackBanner) => {
						fallbackBanner.slotId = banner.slotId;
						fallbackBanner.bannerId = fallbackBanner.bannerId || banner.bannerId;

						const {slotBlockNode, slotBlockName, creativeType} = getSlotBlockData(fallbackBanner);
						if (slotBlockNode) {
							actualDwh = logSlotBlockState(
								{...slotData, slotBlockNode, slotBlockName},
								fallbackBanner,
								{...dwh, creativeType},
							);
						}

						return slotBlockNode;
					});

					if (!logFallback) {
						actualDwh = logSlotBlockState({...slotData, slotBlockNode, slotBlockName}, banner, {
							...dwh,
							creativeType,
						});
					}
				}

				dwhChain.push(actualDwh);
			}
		}

		const frames = slotNode.querySelectorAll('iframe');

		for (let i = frames.length; i--; ) {
			const frame = frames[i];

			handlerKeeper.addEventHandler(frame, 'mouseenter', (event) =>
				onFrameMouseEnterHandler(event, {slotData, dwh, getState, setState}),
			);
			handlerKeeper.addEventHandler(frame, 'mouseleave', () => onFrameMouseLeaveHandler(setState));
		}

		setTimeout(() => checkJbJsonComment(slotData, features), 100);
	};

	if (slotNode) {
		handlerKeeper.addTimer(setTimeout(onReady, directReadyTimeout));

		handlerKeeper.addEventHandler(slotNode, 'click', (event) => {
			onSlotClickHandler(event, {slotData, dwh});
		});
	}
};

export const logJsonSlotState = (slotData: SlotData, mimicData: Slot, params: {dwh: DwhData}) => {
	const {dwh} = params;
	const {slotNode, slotName} = slotData;
	const slotBlockNode = slotNode;
	const slotBlockName = slotName;

	const handlerKeeper = getSlotHandlerKeeper(slotName);

	handlerKeeper.clearAll();

	if (slotNode) {
		logJsonSlotBlockState({...slotData, slotBlockNode, slotBlockName}, mimicData, dwh);
		handlerKeeper.addEventHandler(slotNode, 'click', (event) => {
			logMIMICSendClick({slotData, dwh}, mimicData);
		});
	}
};
