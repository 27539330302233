import {Features} from '@mail/features';

import injector from './injector';

const features: Partial<Features> = {
	has() {
		return null;
	},

	get() {
		return null;
	},
};

const getFeatures = (): typeof features => injector.get('features') || features;

export const getFeatureData = (featureName, dafaultValue) => {
	const features = getFeatures();

	if (features.has(featureName)) {
		const value = features.get(featureName).data;

		if (value) {
			return value;
		}
	}

	return dafaultValue;
};

export const filterURL = (url, defaultData, params) => getFeatures().getAsURL(url, defaultData, params);

export const enum FEATURES {
	ADMAN_HANDLE_LINK = 'adman-handle-link',
	ADMAN_HANDLE_ONLY_SPEC = 'adman-handle-only-spec',
	ADMAN_LOG_NOT_OUR_ADV = 'adman-log-not-our-adv',
	ADMAN_LOG_EMPTY_SLOT_DATA = 'adman-log-empty-slot-data',
	ADMAN_LOG_COMM_SLOT = 'adman-log-comm-slot',
	ADMAN_LOG_ERROR = 'adman-log-error',
	ADMAN_EXP_ID = 'adv-exp-id',
	ADMAN_BLOCKER_IDS = 'adv-blocker-ids',
	ADMAN_LOG_SIDEBAR_LOWER = 'adv-log-sidebar-lower',
	ADMAN_LOG_EMPTY_MIMIC_SLOT_DATA = 'adman-log-empty-mimic-slot-data',
	ADMAN_LOG_BAD_RB_JSON = 'adman-log-bad-rb-json',
}

export default getFeatures;
