import AdmanLoggerOptions from '@/interfaces/AdmanLoggerOptions';
import {SlotClickData, SlotInsertComplete, Status, XrayData} from '@/interfaces/SlotInsertComplete';
import {onComplete} from '@/logger/complete';
import {getData} from '@/logger/data';
import {onError} from '@/logger/error';
import {sendInitLog} from '@/logger/init';
import {onWindowBlurHandler} from '@/logger/mouseActions';
import {onRender} from '@/logger/render';
import hasBlocker, {hasAdBlock, hasAdBlockWhiteList} from '@/utils/adblockerDetector';
import getSlotHandlerKeeper from '@/utils/handlerKeeper';
import {getExpId, getSearchUid, getSlotAllData, getSlotBlockName} from '@/utils/helpers';
import injector from '@/utils/injector';
import inView from '@/utils/inView';
import {getXraySendParams, logAdmanAnalytics, sendXray, updateChain} from '@/utils/xray';

// State
let slotClickData: SlotClickData = null;
let frameMouseLeaveTimer: number = null;

function getState() {
	return {
		slotClickData,
		frameMouseLeaveTimer,
	};
}

function setState(newState) {
	({slotClickData, frameMouseLeaveTimer} = Object.assign(getState(), newState));
}

const logSlotInsertComplete = (data: SlotInsertComplete, source) => {
	const {status} = data.result;

	const {dwh} = getData(data);

	const chain = [];

	const dwhChain = [];

	const xray: XrayData = {
		source,
		chain,
		dwh,
		dwhChain,
	};

	if (status === Status.RENDER) {
		onRender(data, xray, {getState, setState});
	} else {
		if (status !== Status.INSERT_TIMEOUT) {
			onError(data, xray);
		}

		onComplete(data, xray);
	}

	if (chain.length) {
		sendXray(xray);
	}

	return dwhChain;
};

/**
 * Главный класс
 */
export default class AdmanLogger {
	static handlerKeeper = getSlotHandlerKeeper;
	static inView = inView;
	static injector = injector;
	static getSearchUid = getSearchUid;
	static getSlotBlockName = getSlotBlockName;
	static getXraySendParams = getXraySendParams;
	static updateChain = updateChain;
	static logAdmanAnalytics = logAdmanAnalytics;
	static hasAdBlock = hasAdBlock;
	static hasAdBlockWhiteList = hasAdBlockWhiteList;
	static hasBlocker = hasBlocker;
	static getExpId = getExpId;

	static getSlotData(html) {
		return getSlotAllData(html)?.[0];
	}

	constructor(options: AdmanLoggerOptions) {
		const {logger, features, xray} = options;

		injector.setAll({
			logger,
			features,
			xray,
		});

		setTimeout(() => {
			sendInitLog();
		}, 2000);

		window.addEventListener('blur', () => onWindowBlurHandler(getState));
	}

	slotInsertComplete(data: SlotInsertComplete, source = 'adman') {
		return logSlotInsertComplete(data, source);
	}
}
