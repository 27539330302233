import throttle from 'lodash.throttle';

const inViewport = (element, options) => {
	const {top, right, bottom, left, width, height} = element.getBoundingClientRect();
	const intersection = {
		t: bottom,
		r: window.innerWidth - left,
		b: window.innerHeight - top,
		l: right,
	};

	const threshold = {
		x: options.threshold * width,
		y: options.threshold * height,
	};

	return (
		intersection.t > options.offset.top + threshold.y &&
		intersection.r > options.offset.right + threshold.x &&
		intersection.b > options.offset.bottom + threshold.y &&
		intersection.l > options.offset.left + threshold.x
	);
};

const isNum = (n) => typeof n === 'number';

const inView = () => {
	let idx = 0;

	const watchData = {};

	const interval = 100;
	const triggers = ['scroll', 'resize', 'load'];

	const options = {offset: {}, threshold: 0, test: inViewport};

	let init = false;

	const control: any = {};

	const check = throttle(() => {
		for (const id in watchData) {
			const {element, handler} = watchData[id];

			if (control.is(element)) {
				handler(element);

				control.unwatch(id);
			}
		}
	}, interval);

	const addListeners = () => {
		triggers.forEach((event) => addEventListener(event, check));

		/*
		if ((window as any).MutationObserver) {
			addEventListener('DOMContentLoaded', () => {
				new MutationObserver(check)
					.observe(document.body, {
						attributes: true,
						childList: true,
						subtree: true,
					});
			});
		}
		*/
	};

	control.offset = (o) => {
		if (o === undefined) {
			return options.offset;
		}

		['top', 'right', 'bottom', 'left'].forEach(
			isNum(o)
				? (dim) => {
						options.offset[dim] = o;
						return o;
				  }
				: (dim) => {
						if (isNum(o[dim])) {
							options.offset[dim] = o[dim];
							return o[dim];
						}
						return null;
				  },
		);
		return options.offset;
	};

	control.threshold = (n) => {
		if (typeof n === 'number' && n >= 0 && n <= 1) {
			options.threshold = n;
		}
		return options.threshold;
	};

	control.is = (el) => options.test(el, options);

	control.watch = (element, handler) => {
		if (!init) {
			addListeners();
			init = true;
		}

		const id = idx++;

		watchData[id] = {
			element,
			handler,
		};

		return id;
	};

	control.unwatch = (id) => {
		delete watchData[id];
	};

	control.offset(0);

	return control;
};

export default inView();
