import * as Cookie from 'js-cookie';

import {BannerData, Slot, XrayData} from '@/interfaces/SlotInsertComplete';
import {hasAdBlock, hasAdBlockWhiteList} from '@/utils/adblockerDetector';
import {sendXrayWithChain} from '@/utils/xray';

import {
	LINK_PROTOCOL_RE,
	RADAR_SOURCE_MAP,
	RB_JSON_PLACEHOLDER,
	RB_JSON_RE,
	TARGET_CREATIVE_TYPE_RE,
} from './constants';
import getFeatures, {FEATURES, getFeatureData} from './features';

export const getSearchUid = () => Cookie.get('searchuid');

export const getFolderId = () => window['app']?.model?.folder?.id;

export const isSlotHasHtml = ({html}: Slot): boolean => !!(html && String(html).trim());

export const prepareStrForRlog = (str: string) => {
	if (typeof str === 'string') {
		str = str.replace(/(\r\n|\n|\r)/gm, '');
		// eslint-disable-next-line unicorn/prefer-string-slice
		str = str.substr(0, 1000);
	}
	return str;
};

const hasRbJsonComment = (str: string): boolean => {
	try {
		return str.indexOf(RB_JSON_PLACEHOLDER) !== -1;
	} catch (error) {
		return false;
	}
};

export const isCommercialSlot = (slot: Slot): boolean => {
	if (isSlotHasHtml(slot)) {
		const {html} = slot;
		if (
			hasRbJsonComment(html) ||
			html.indexOf('Ya.Context') !== -1 ||
			html.indexOf('yandexContextAsyncCallbacks') !== -1 ||
			html.indexOf('trg-v-banner') !== -1 ||
			html.indexOf('yaads') !== -1 ||
			html.indexOf('commercial') !== -1
		) {
			return true;
		}
	}
	return false;
};

export const getSlotBlockName = ({source, format}: BannerData): string => {
	source = source.toLowerCase();

	source = RADAR_SOURCE_MAP[source] || source;

	if (format) {
		format = format.toLowerCase();

		return `${source}-${format}`;
	}

	return source;
};

export const getJSONSlotName = ({mark, format}: Slot): string => {
	let source = mark === 'Директ' ? 'direct' : 'target';

	source = source.toLowerCase();
	source = RADAR_SOURCE_MAP[source] || source;

	if (format) {
		format = format.toLowerCase();

		return `${source}-${format}`;
	}

	return source;
};

export const getTargetCreativeType = (html: string): string | undefined => {
	const matches = html.match(TARGET_CREATIVE_TYPE_RE);
	if (matches) {
		return matches[1];
	}
};

export const getSlotAllData = (html): BannerData[][] => {
	const result = [];
	if (hasRbJsonComment(html)) {
		let matches = null;
		do {
			matches = RB_JSON_RE.exec(html);
			if (matches) {
				try {
					result.push(JSON.parse(matches[1]));
				} catch (error) {
					const features = getFeatures();

					const xray: XrayData = {};

					// eslint-disable-next-line max-depth
					if (features.has(FEATURES.ADMAN_LOG_BAD_RB_JSON)) {
						xray.rlog = {
							name: 'bad_rb_json',
							message: {
								data: prepareStrForRlog(matches[1]),
							},
						};
					}

					sendXrayWithChain(xray, ['bad_rb_json']);

					// eslint-disable-next-line max-depth
					try {
						// eslint-disable-next-line no-new-func
						result.push(new Function(`return ${matches[1]}`)());
					} catch (error) {}
				}
			}
		} while (matches);
	}
	return result;
};

export const hasRbJsonCommentInNode = (slotNode: HTMLElement): boolean => {
	try {
		return hasRbJsonComment(slotNode.innerHTML);
	} catch (error) {
		return false;
	}
};

const findClosestLink = (node, context: HTMLElement): HTMLLinkElement => {
	while (node && node !== context) {
		if (node.tagName === 'A') {
			return node;
		}
		node = node.parentNode;
	}
};

const findClosestById = (id: string, node, context: HTMLElement): HTMLElement => {
	while (node && node !== context) {
		if (node.id === id) {
			return node;
		}
		node = node.parentNode;
	}
};

export const findSlotBlockLink = (node: HTMLElement, context: HTMLElement): HTMLLinkElement => {
	const link = findClosestLink(node, context);

	if (link && LINK_PROTOCOL_RE.test(link.href)) {
		return link;
	}
};

export const findSlotBlockByNode = (
	slotAllHTMLData: BannerData[][],
	target: HTMLElement,
	context: HTMLElement,
): BannerData => {
	for (const slotHTMLData of slotAllHTMLData) {
		for (const banner of slotHTMLData) {
			const {nodeId, fallback = []} = banner;

			if (findClosestById(nodeId, target, context)) {
				return banner;
			}

			for (const fallbackBanner of fallback) {
				fallbackBanner.slotId = banner.slotId;
				fallbackBanner.bannerId = fallbackBanner.bannerId || banner.bannerId;

				const {nodeId} = fallbackBanner;

				if (findClosestById(nodeId, target, context)) {
					return fallbackBanner;
				}
			}
		}
	}
};

export const getBrowserLabel = () => {
	let browser = 'unk';

	const ua = navigator.userAgent;

	if (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1) {
		browser = 'opr';
	} else if (ua.indexOf('Edge') !== -1) {
		browser = 'edg';
	} else if (ua.indexOf('Chrome') !== -1) {
		browser = 'chr';
	} else if (ua.indexOf('Safari') !== -1) {
		browser = 'sfr';
	} else if (ua.indexOf('Firefox') !== -1) {
		browser = 'ff';
	} else if (ua.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
		browser = 'ie';
	}

	return browser;
};

export const submitForm = (action: string, data) => {
	const form = document.createElement('form');

	form.action = action;
	form.method = 'post';
	form.target = '_blank';

	for (const key in data) {
		const input = document.createElement('input');

		input.name = key;
		input.value = data[key];
		input.type = 'hidden';

		form.appendChild(input);
	}

	document.body.appendChild(form);

	form.submit();

	document.body.removeChild(form);
};

export function getExpId() {
	const ids = getFeatureData(FEATURES.ADMAN_BLOCKER_IDS, {});
	const expId = getFeatureData(FEATURES.ADMAN_EXP_ID, '');
	if (hasAdBlockWhiteList()) {
		// есть AdBlock и белый список
		return ids.WL;
	} else if (hasAdBlock()) {
		// есть AdBlock
		return ids[expId] || ids['default'];
	}
	return expId;
}
