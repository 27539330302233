define('toolkit-common/jquery/__fest/jquery__fest', ['jquery', 'mrg-i18n-utils'], function($, i18n) {

	if (!window.fest) {
		window.fest = {};
	}

	window.fest.i18n = i18n;

	// async & sync
	$.fest = function (url, data, callback, options) {
		var opts = $.extend( {}, $.fn.fest.defaults, options );

		var success = function () {
			var html;

			if (window.fest[url]) {
				html = window.fest[url](data);
			}
			else {
				$(window).triggerHandler('fest.notDefinedTemplateMethod', [url]);
			}

			if (typeof callback === 'function') {
				callback(html);
			}

			return html;
		};

		if (window.fest && window.fest[url] !== undefined) {
			return success();
		}
		else {
			var script = opts.basePath + url + '.js';

			$(window).triggerHandler('fest.connection.progress', [script]);

			$.getScript(script)
				.done(function (response, status, jqXHR) {
					$(window).triggerHandler('fest.connection.success', [script, success]);
				})
				.fail(function (jqXHR, settings, exception) {
					$(window).triggerHandler('fest.connection.error', [script, exception]);
				})
			;
		}
	};

	// async
	$.fn.fest = function (url, data, callback){
		$.fest(url, data, function(html) {
			this.trigger('destroy.view');

			this.each(function () {
				while( this.firstChild ) {
					this.removeChild(this.firstChild);
				}

				this.innerHTML = html;
			});

			// ATTENTION: jquery.fest.js & b-toolkit.js
			if( typeof html === 'string' && html.indexOf('data-rb="show"') > -1 ){
				this.find('[data-rb="show"]').trigger('rb-show');
			}

			if(callback && callback instanceof Function) {
				callback.call();
			}

		}.bind(this));

		return this;
	};

	$.fn.fest.defaults = {
		basePath: '/fest/'
	}

});
