import {SlotInsertComplete, XrayData} from '@/interfaces/SlotInsertComplete';
import {getData} from '@/logger/data';
import {FEATURES} from '@/utils/features';
import {prepareStrForRlog} from '@/utils/helpers';

export const onError = (data: SlotInsertComplete, xray: XrayData): void => {
	const {status} = data.result;
	const {errorThrown, jqXHR, requestParams} = data.result;
	const {features, slot, slotId, slotName} = getData(data);

	const rlogMessage: any = {
		slotId,
		slotName,
	};

	if (errorThrown) {
		rlogMessage.errorThrown = errorThrown;
	}

	if (slot && slot.banner) {
		rlogMessage.banner = slot.banner;
	}

	if (requestParams) {
		rlogMessage.requestParams = requestParams;
	}

	if (jqXHR) {
		if (jqXHR.responseText) {
			rlogMessage.xhrResponseText = prepareStrForRlog(jqXHR.responseText);
		}

		if (jqXHR.status) {
			rlogMessage.xhrStatus = jqXHR.status;
		}
	}

	if (features.has(FEATURES.ADMAN_LOG_ERROR)) {
		xray.rlog = {
			name: status,
			message: rlogMessage,
		};
	}
};
