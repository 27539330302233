import {SlotInsertComplete, XrayData} from '@/interfaces/SlotInsertComplete';
import {getData} from '@/logger/data';
import {logJsonSlotState, logSlotState} from '@/logger/logSlotState';
import {FEATURES} from '@/utils/features';
import {getSlotAllData, isCommercialSlot, isSlotHasHtml, prepareStrForRlog} from '@/utils/helpers';
import {updateChain} from '@/utils/xray';

function onRenderSlotWithHtml(data: SlotInsertComplete, xray: XrayData, state): void {
	const {status} = data.result;
	const {features, slot, slotData, slotId, slotName, time} = getData(data);
	const {chain, dwh, dwhChain} = xray;
	const {html = '', ...fields} = slot;
	const {getState, setState} = state;

	if (isCommercialSlot(slot)) {
		const slotAllHTMLData = getSlotAllData(html);
		if (slotAllHTMLData.length) {
			logSlotState({...slotData, slotAllHTMLData}, {dwh, dwhChain, setState, getState});
		} else {
			updateChain(chain, ['empty_slot_data'], slotData);

			if (features.has(FEATURES.ADMAN_LOG_EMPTY_SLOT_DATA)) {
				xray.rlog = {
					name: 'empty_slot_data',
					message: {
						...fields,
						html: prepareStrForRlog(html),
						slotId,
						slotName,
					},
				};
			}
		}

		updateChain(chain, ['complete', status], slotData, String(time));
	} else if (features.has(FEATURES.ADMAN_LOG_COMM_SLOT)) {
		xray.rlog = {
			name: 'comm-slot',
			message: {
				...fields,
				html: prepareStrForRlog(html),
				slotId,
				slotName,
			},
		};
	}

	updateChain(chain, ['has_answer'], slotData);
}

function onRenderSlotWithoutHtml(data: SlotInsertComplete, xray: XrayData): void {
	const {status} = data.result;
	const {slotData, slotRequiresHtml, time} = getData(data);
	const {chain} = xray;

	if (slotRequiresHtml) {
		updateChain(chain, ['complete', status], slotData, String(time));
	}

	updateChain(chain, ['empty_answer'], slotData);
}

function onRenderMimicSlot(data: SlotInsertComplete, xray: XrayData): void {
	const {status} = data.result;
	const {slotData, time, slot, features, slotId, slotName} = getData(data);
	const {chain, dwh} = xray;

	if (slot.ads && slot.ads.length > 0) {
		logJsonSlotState({...slotData}, slot, {dwh});
		updateChain(chain, ['complete', status], slotData, String(time));
	} else {
		updateChain(chain, ['empty_answer'], slotData);

		if (features.has(FEATURES.ADMAN_LOG_EMPTY_MIMIC_SLOT_DATA)) {
			xray.rlog = {
				name: 'empty_answer',
				message: {
					...slot,
					slotId,
					slotName,
				},
			};
		}
	}

	updateChain(chain, ['has_answer'], slotData);
}

export const onRender = (data: SlotInsertComplete, xray: XrayData, state): void => {
	const {slot, slotCounter} = getData(data);

	if (slotCounter) {
		return;
	}

	const {source} = xray;

	if (isSlotHasHtml(slot)) {
		onRenderSlotWithHtml(data, xray, state);
	} else if (source === 'mimic') {
		onRenderMimicSlot(data, xray);
	} else {
		onRenderSlotWithoutHtml(data, xray);
	}
};
