import {DwhData, Slot, SlotClickData, SlotData} from '@/interfaces/SlotInsertComplete';
import hasBlocker from '@/utils/adblockerDetector';
import getFeatures, {FEATURES, filterURL, getFeatureData} from '@/utils/features';
import {
	findSlotBlockByNode,
	findSlotBlockLink,
	getJSONSlotName,
	getSlotAllData,
	submitForm,
} from '@/utils/helpers';
import injector from '@/utils/injector';
import {logAdmanAnalytics, sendXrayWithChain} from '@/utils/xray';

import {getSlotBlockData} from './data';

export const logSlotClick = ({target, slotData, dwh}: SlotClickData) => {
	const features = getFeatures();
	const logSidebarLower = features.has(FEATURES.ADMAN_LOG_SIDEBAR_LOWER);

	const {slotNode, slotAllHTMLData} = slotData;

	const slotAllHTMLDataAfterRender = logSidebarLower ? getSlotAllData(slotNode.innerHTML) : slotAllHTMLData;

	const banner = findSlotBlockByNode(slotAllHTMLDataAfterRender, target, slotNode);

	if (banner) {
		const {
			slotId,
			bannerId,
			source: bannerSource,
			format: bannerFormat,
			count: bannerCount,
			is_expensive: isExpensive,
		} = banner;

		const {slotBlockName, creativeType} = getSlotBlockData(banner);

		dwh = {
			...dwh,
			bannerId,
			bannerSource,
			isExpensive,
			bannerFormat,
			bannerCount,
			creativeType,
		};

		// FMAIL-22838
		if (slotId && slotId !== dwh.slotId) {
			dwh.slotId = slotId;
		}

		sendXrayWithChain({dwh}, ['click'], {...slotData, slotBlockName});
		logAdmanAnalytics('adman', dwh, 'click');
	}
};

export const logMIMICSendClick = ({slotData, dwh}: SlotClickData, mimicData: Slot) => {
	const bannerSource = mimicData.mark === 'Директ' ? 'direct' : 'target';
	const bannerFormat = mimicData.format;
	const isExpensive = mimicData.direct_is_expensive;
	const slotBlockName = getJSONSlotName(mimicData);

	dwh = {
		...dwh,
		bannerSource,
		bannerFormat,
		isExpensive,
	};

	sendXrayWithChain({dwh, source: 'mimic'}, ['click'], {...slotData, slotBlockName});
	logAdmanAnalytics('mimic', dwh, 'click');
};

export const onSlotClickHandler = (evt: MouseEvent, params: {slotData: SlotData; dwh: DwhData}) => {
	const {slotData, dwh} = params;
	const features = getFeatures();
	const target = evt.target as HTMLElement;
	const {slotNode} = slotData;

	// Вот этот кусок не имеет прямого отношения к adman-logger
	if (
		features.has(FEATURES.ADMAN_HANDLE_LINK) &&
		(!features.has(FEATURES.ADMAN_HANDLE_ONLY_SPEC) || hasBlocker())
	) {
		const link = findSlotBlockLink(target, slotNode);
		if (link) {
			let whitelistHosts = injector.get('onClickWithBlockerWhitelistHosts'); // FMAIL-16463
			if (whitelistHosts) {
				whitelistHosts = whitelistHosts.replace(/,/g, '|').replace(/\./g, '\\.');

				whitelistHosts = new RegExp(`^https?:\\/\\/(${whitelistHosts})($|\\/)`);
				if (!whitelistHosts.test(link.href)) {
					return;
				}
			}

			const action = getFeatureData(FEATURES.ADMAN_HANDLE_LINK, '');

			// Ничего не делаем, если URL какой-то левый
			if (
				filterURL(FEATURES.ADMAN_HANDLE_LINK, action, {mail: true, imgsmail: true, local: true}) ===
				null
			) {
				return;
			}

			submitForm(action, {
				mimic_link: link.href,
			});

			evt.preventDefault();
		}
	}

	logSlotClick({target, slotData, dwh});
};
