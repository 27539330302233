import {SlotData, XrayData} from '@/interfaces/SlotInsertComplete';
import {FEATURES} from '@/utils/features';
import {hasRbJsonCommentInNode, prepareStrForRlog} from '@/utils/helpers';
import {sendXrayWithChain} from '@/utils/xray';
export const checkJbJsonComment = (slotData: SlotData, features) => {
	const {slotNode, slotName} = slotData;

	if (slotNode && !hasRbJsonCommentInNode(slotNode)) {
		const xray: XrayData = {};

		if (features.has(FEATURES.ADMAN_LOG_NOT_OUR_ADV)) {
			xray.rlog = {
				name: 'not_our_adv',
				message: {
					slotName,
					slotNodeInnerHTML: prepareStrForRlog(slotNode.innerHTML),
				},
			};
		}

		sendXrayWithChain(xray, ['not_our_adv']);
	}
};
