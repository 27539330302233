/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable max-depth */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const hasAdBlock = () => {
	return window['__knownBlocker'];
};

export const hasWhiteList = () => {
	return window['abp'];
};

export const hasAdBlockWhiteList = () => {
	return window['__knownBlocker'] && window['abp'];
};

/**
 * AdBlock & Adblock Plus
 *
 * https://chrome.google.com/webstore/detail/gighmmpiobklfepjocnamgkkbiglidom
 * https://chrome.google.com/webstore/detail/cfhdojbkjhnklbpkdaibdccddilifddb
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const adblockDetector = () => {
	const descriptor = Object.getOwnPropertyDescriptor(HTMLIFrameElement.prototype, 'contentWindow');
	if (descriptor && descriptor.get) {
		const source = descriptor.get.toString();
		if (source.indexOf('injectIntoContentWindow') !== -1) {
			return 'adblock';
		}
	}
	const ua = navigator['userAgent'];
	if (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1) {
		const head = document.querySelector('head');
		if (head) {
			const nodes = head.querySelectorAll('style');
			let nodeIndex = 0;
			const nodesLength = nodes.length;
			for (; nodeIndex < nodesLength; nodeIndex++) {
				try {
					const node = nodes[nodeIndex];
					if (node && !node.textContent && node.sheet) {
						const rules = node.sheet.rules;
						if (rules) {
							let ruleIndex = 0;
							const rulesLength = rules.length;
							for (; ruleIndex < rulesLength; ruleIndex++) {
								try {
									const rule = rules[ruleIndex];
									if (rule.cssText.indexOf('{ display: none !important; visibility: hidden !important; orphans: 4321 !important; }') !== -1) {
										return 'adblock';
									}
								} catch (error) {
								}
							}
						}
					}
				} catch (error) {
				}
			}
		}
	}
};

/**
 * Ad Blocker Ultimate
 *
 * https://chrome.google.com/webstore/detail/ohahllgiabjaoigichmmfljhkcfikeof
 */
const adblockUltimateDetector = () => {
	const descriptor = Object.getOwnPropertyDescriptor(HTMLIFrameElement.prototype, 'contentWindow');
	if (descriptor && descriptor.get) {
		const source = descriptor.get.toString();
		if (source.indexOf('injectPageScriptAPIInWindow') !== -1 && source.indexOf('var contentWindow') !== -1) {
			return 'ultimate';
		}
	}
	const ua = navigator['userAgent'];
	if (window['AdFox_getCodeScript'] && (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1)) {
		return 'ultimate';
	}
};

/**
 * AdBlocker by Trustnav
 *
 * https://chrome.google.com/webstore/detail/dgbldpiollgaehnlegmfhioconikkjjh
 */
const adblockTrustnavDetector = () => {
	let source = '';
	try {
		// Function.prototype.toString is not generic
		// Chrome/49.0.2623.112
		source = document.createElement.toString();
	} catch (error) {
	}
	if (source.indexOf('blocked due to an explicit dispatchEvent') !== -1) {
		return 'trustnav';
	}
};

/**
 * uBlock Origin
 *
 * https://chrome.google.com/webstore/detail/cjpalhdlnbpafiamejdnhcphjbkeiagm
 */
const ublockOriginDetector = () => {
	const descriptor = Object.getOwnPropertyDescriptor(Object.prototype, 'onLinkMouseDown')
		|| Object.getOwnPropertyDescriptor(Object.prototype, 'adsRectangle')
		|| Object.getOwnPropertyDescriptor(Object.prototype, 'mimic')
		|| Object.getOwnPropertyDescriptor(Object.prototype, 'adblock');
	if (descriptor && descriptor.get) {
		const source = descriptor.get.toString();
		if (source.indexOf('return handler.getter(); // cValue') !== -1) {
			return 'ublock_origin';
		}
	}
};

/**
 * Opera Ad blocker
 *
 * https://www.opera.com/ru/features/ad-blocker
 */
const operaDetector = () => {
	const ua = navigator['userAgent'];
	if (ua.indexOf('Opera') !== -1 || ua.indexOf('OPR') !== -1) {
		const head = document.querySelector('head');
		if (head) {
			const nodes = head.querySelectorAll('style');
			let nodeIndex = 0;
			const nodesLength = nodes.length;
			for (; nodeIndex < nodesLength; nodeIndex++) {
				try {
					const node = nodes[nodeIndex];
					if (node && /:root .*? { display: none !important; }/.test(node.textContent)) {
						return 'opera';
					}
				} catch (error) {
				}
			}
		}
	}
};

/**
 * AdGuard (app)
 *
 * https://adguard.com/
 */
const adguardAppDetector = () => {
	const head = document.querySelector('head');
	if (head) {
		const nodes = head.querySelectorAll('style');
		let nodeIndex = 0;
		const nodesLength = nodes.length;
		for (; nodeIndex < nodesLength; nodeIndex++) {
			try {
				const node = nodes[nodeIndex];
				if (node) {
					const nonce = node.nonce;
					if (nonce && document[nonce]) {
						return 'adguard_app';
					}
				}
			} catch (error) {
			}
		}
	}
};

/**
 * AdGuard (extension)
 *
 * https://chrome.google.com/webstore/detail/bgnkhhnnamicmpeenaelnjfhikgbkllg
 */
const adguardExtensionDetector = () => {
	const descriptor = Object.getOwnPropertyDescriptor(HTMLIFrameElement.prototype, 'contentWindow');
	if (descriptor && descriptor.get) {
		const source = descriptor.get.toString();
		if (source.indexOf('injectPageScriptAPIInWindow') !== -1 && source.indexOf('const contentWindow') !== -1) {
			return 'adguard_ext';
		}
	}
};

/**
 * AdGuard (extra)
 *
 * https://chrome.google.com/webstore/detail/gkeojjjcdcopjkbelgbcpckplegclfeg
 */
const adguardExtraDetector = () => {
	let source = '';
	try {
		source = XMLHttpRequest.prototype.open.toString();
	} catch (error) {
	}
	if (source.indexOf('if (shouldBlock(request)) {') !== -1 && source.indexOf('return origOpen.apply(this, arguments);') !== -1) {
		return 'adguard_extra';
	}
};

let blockers = null;

export const getBlockers = () => {
	if (blockers === null) {
		blockers = [];

		const detectors = [
			adblockDetector,
			adguardExtraDetector,
			adguardExtensionDetector,
			adguardAppDetector,
			operaDetector,
			ublockOriginDetector,
			adblockUltimateDetector,
			adblockTrustnavDetector,
		];

		for (const detector of detectors) {
			try {
				const name = detector();
				if (name) {
					blockers.push(name);
				}
			} catch (error) {}
		}
	}
	return blockers;
};

let prefixes = null;

export const getPrefixes = () => {
	if (prefixes === null) {
		prefixes = [];

		if (hasAdBlock()) {
			prefixes.push('adblock_known_blocker');
		}

		if (hasAdBlockWhiteList()) {
			prefixes.push('adblock_white_list');
		}

		if (hasWhiteList()) {
			prefixes.push('block_white_list');
		}
	}

	return prefixes;
};

const hasBlocker = () => getBlockers().length;

export default hasBlocker;
