import Dependencies from '@/interfaces/Dependencies';

const dependencies: Partial<Dependencies> = {};

export default {
	setAll(dependencies: Dependencies) {
		for (const depName in dependencies) {
			this.set(depName, dependencies[depName]);
		}
	},

	set<K extends keyof Dependencies>(name: K, dependency: Dependencies[K]) {
		dependencies[name] = dependency;
	},

	get(name: keyof Dependencies) {
		return dependencies[name];
	},
};
