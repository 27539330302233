import {getData} from '@/logger/data';
import {updateChain} from '@/utils/xray';

export const onComplete = (data, xray) => {
	const {status} = data.result;
	const {slotData, time} = getData(data);
	const {chain} = xray;

	updateChain(chain, ['complete', status], slotData, String(time));
};
