import {Params} from '@mail/xray';
import * as Cookie from 'js-cookie';

import {SlotData, XrayData} from '@/interfaces/SlotInsertComplete';

import {getBlockers, getPrefixes} from './adblockerDetector';
import {
	RADAR_BLOCKER_MAP,
	RADAR_NAME_MAP,
	RADAR_NAME_PREFIXES_MAP,
	RADAR_SLOT_MAP,
	RADAR_SOURCE_MAP,
	RLOG_VERSION,
} from './constants';
import {getBrowserLabel, getExpId, getFolderId, getSearchUid} from './helpers';
import injector from './injector';

const getXray = () => injector.get('xray');

const filterDwh = (dwh) => {
	Object.keys(dwh)
		.filter((key) => dwh[key] === undefined || dwh[key] === null)
		.forEach((key) => delete dwh[key]);
	return dwh;
};

export const getXraySendParams = (xrayData: XrayData) => {
	const {dwh = {}, additionalFields = {}, chain, rlog, source = 'adman'} = xrayData;

	const xrayParams: Params = {
		...additionalFields,
		i: chain,
		dwh: filterDwh({
			...dwh,
			rbcnt: Cookie.get('rbcnt'),
			searchuid: getSearchUid(),
			folderId: getFolderId(),
		}),
	};

	if (rlog) {
		const {name, message} = rlog;

		xrayParams.rlog = `${source}_${name}_${RLOG_VERSION}`;
		xrayParams.rlog_message = message;
	}

	return xrayParams;
};

export const sendXray = (xrayData: XrayData) => {
	const {source = 'adman'} = xrayData;
	const xray = getXray();
	const xrayParams = getXraySendParams(xrayData);
	xray.send(source, xrayParams);
};

export const updateChain = (chain: string[], names: string[], slotData: SlotData, value = '1') => {
	const browser = getBrowserLabel();
	const blockers = getBlockers();
	const prefixes = getPrefixes();

	for (let name of names) {
		name = RADAR_NAME_MAP[name] || name;

		chain.push(`${name}:${value}`);

		chain.push(`br-${browser}_${name}:${value}`);

		for (let blocker of blockers) {
			blocker = RADAR_BLOCKER_MAP[blocker] || blocker;

			chain.push(`bl-${blocker}_br-${browser}_${name}:${value}`);
		}

		for (let prefix of prefixes) {
			prefix = RADAR_NAME_PREFIXES_MAP[prefix] || prefix;

			chain.push(`pr-${prefix}_br-${browser}_${name}:${value}`);
		}

		if (slotData) {
			let {slotName} = slotData;

			if (slotName) {
				slotName = RADAR_SLOT_MAP[slotName] || slotName;

				chain.push(`s-${slotName}_${name}:${value}`);

				const expId = getExpId();
				if (expId) {
					chain.push(`s-${slotName}_${name}_${expId}:${value}`);
				}

				const {slotBlockName} = slotData;

				if (slotBlockName) {
					chain.push(`b-${slotBlockName}_${name}:${value}`);
					chain.push(`s-${slotName}_b-${slotBlockName}_${name}:${value}`);
				}
			}
		}
	}
};

export const sendXrayWithChain = (xray: XrayData, names: string[], slotData: SlotData = null) => {
	const chain = [];
	updateChain(chain, names, slotData);
	sendXray({...xray, chain});
};

// FMAIL-20855
export const logAdmanAnalytics = (source, dwh, type = 'vis-1') => {
	const {bannerSource, isExpensive} = dwh;
	const bannerSourceAbbr = RADAR_SOURCE_MAP[bannerSource] || bannerSource;

	let chain = [];

	chain.push(
		`${bannerSourceAbbr}_mimic-${source === 'mimic' ? '1' : '0'}`,
		bannerSourceAbbr,
		`mimic-${source === 'mimic' ? '1' : '0'}`,
	);

	if (bannerSource === 'direct') {
		chain.push(
			`${bannerSourceAbbr}_mimic-${source === 'mimic' ? '1' : '0'}_ied-${
				isExpensive === 'True' ? '1' : '0'
			}`,
			`${bannerSourceAbbr}_ied-${isExpensive === 'True' ? '1' : '0'}`,
		);
	}

	if (type === 'click') {
		chain = chain.map((i) => `clk_${i}`);
	}

	sendXray({
		source: 'adman-anlt',
		chain,
	});
};

export default getXray;
