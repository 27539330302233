export const RLOG_VERSION = 3;

const enum BLOCKS {
	'240x400' = '240x400',
	ANIBANNER = 'anibanner',
	BANNER = 'banner',
	EMPTY = '',
	MULTI = 'multi',
	PULS = 'puls',
	TEASER = 'teaser',
}

interface SlotSource {
	name: string;
	abbreviation?: string;
	blocks: BLOCKS[];
}

export const SLOT_SOURCES: SlotSource[] = [
	{
		name: 'direct',
		abbreviation: 'drt',
		blocks: [BLOCKS.TEASER],
	},
	{
		name: 'target',
		abbreviation: 'trg',
		blocks: [
			BLOCKS.TEASER,
			BLOCKS.MULTI,
			BLOCKS.BANNER,
			BLOCKS.ANIBANNER,
			// 'fullscreen',
		],
	},
	{
		name: 'marketing',
		abbreviation: 'mrk',
		blocks: [
			BLOCKS.TEASER,
			BLOCKS.MULTI,
			// 'banner',
			BLOCKS['240x400'], // выпилить как все обновлят adman-logger
			BLOCKS.PULS,
		],
	},
	{
		name: 'commercial',
		abbreviation: 'comm',
		blocks: [
			BLOCKS.EMPTY, // бывает пустой
			BLOCKS.BANNER,
		],
	},
	{
		name: 'criteo',
		abbreviation: 'crt',
		blocks: [BLOCKS.MULTI, BLOCKS.BANNER],
	},
	{
		name: 'vk',
		blocks: [BLOCKS.MULTI],
	},
	{
		name: 'otherdsp',
		abbreviation: 'odsp',
		blocks: [BLOCKS.MULTI, BLOCKS.BANNER],
	},
];

export const RADAR_SOURCE_MAP = {};

SLOT_SOURCES.forEach(({name, abbreviation}) => {
	if (abbreviation) {
		RADAR_SOURCE_MAP[name] = abbreviation;
	}
});

export const RADAR_SLOT_MAP = {
	'sidebar-small': 'side-small',
};

export const RADAR_NAME_MAP = {
	visible: 'vis',
	visible_in_view: 'vis-1',
	visible_in_view_start: 'vis-2',
	visible_in_view_after: 'vis-3',
	visible_no_in_view: 'vis-4',

	hidden: 'hid',
	hidden_visible: 'hid-1',
	hidden_no_in_dom: 'hid-2',

	email_as_search_uid: 'eml_as_srh_uid',
	empty_search_uid: 'empty_srh_uid',
};

export const RADAR_BLOCKER_MAP = {
	adblock: 'adb',
	adguard_ext: 'adge',
	adguard_app: 'adga',
	adguard_extra: 'adgex',
	opera: 'oprb',
	ultimate: 'ult',
	trustnav: 'trs',
	ublock_origin: 'ublor',
};

export const RADAR_NAME_PREFIXES_MAP = {
	adblock_known_blocker: 'adbkb',
	adblock_white_list: 'adbwl',
	block_white_list: 'bwl',
};

export const RB_JSON_PLACEHOLDER = 'RB-JSON:';

export const RB_JSON_RE = new RegExp(`<!--${RB_JSON_PLACEHOLDER}([\\s\\S]*?)-->`, 'g');

export const TARGET_CREATIVE_TYPE_RE = /creativeType.*?:.*?['"](.*?)['"]/;

// eslint-disable-next-line unicorn/no-unsafe-regex
export const LINK_PROTOCOL_RE = /^(https?:)?\/\//;
